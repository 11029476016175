import type { IntlShape } from "react-intl"

import type {
  RefLabelGroupJsonldReadCollectionRefLabelGroup,
  RefLabelGroupJsonldReadItemRefLabelGroupItem,
  RefLabelJsonldReadCollectionRefLabel,
  RefLabelJsonldReadItemAgencyItem,
  RefLabelJsonldReadItemClusterItem,
  RefLabelJsonldReadItemCollaboratorItem,
  RefLabelJsonldReadItemProperty,
  RefLabelJsonldReadItemRefLabelItem,
} from "@/networking/api"

import { isBlank, isNullOrUndefined } from "@/utils/ts-utils"
import type { Prettify } from "@/utils/types/utils"

export type Translatable = { locale?: string; label?: string }
export type LocalRefLabel = Prettify<
  // | RefLabelJsonld
  | RefLabelJsonldReadItemRefLabelItem
  | RefLabelJsonldReadCollectionRefLabel
  | RefLabelJsonldReadItemProperty
  | RefLabelJsonldReadItemCollaboratorItem
  | RefLabelJsonldReadItemAgencyItem
  | RefLabelJsonldReadItemClusterItem
>
export type LocalRefGroup = Prettify<
  // | RefLabelGroupJsonld
  | RefLabelGroupJsonldReadItemRefLabelGroupItem
  | RefLabelGroupJsonldReadCollectionRefLabelGroup
  | null
>

export const get_t11n = <T extends Translatable>(
  translations?: T[],
  locale?: string,
  defaultLocale?: string,
  defaultText?: string
): string => {
  const dft = defaultText ?? ""
  if (isNullOrUndefined(translations) || translations.length === 0) return dft

  let locale_text = ""
  let dft_locale_text = ""

  translations.map(t => {
    if (t.locale?.split("_").shift() === locale) locale_text = t.label ?? ""
    if (t.locale?.split("_").shift() === defaultLocale) dft_locale_text = t.label ?? ""
  })

  return isBlank(locale_text) ? (isBlank(dft_locale_text) ? dft : dft_locale_text) : locale_text
}

export const get_t11n_label = (label?: LocalRefLabel | null, intl?: IntlShape): string => {
  if (Object.keys(intl?.messages ?? {}).includes(`labels.${label?.code ?? ""}`))
    return intl?.formatMessage({ id: `labels.${label?.code ?? ""}` }) ?? ""

  return get_t11n(
    label?.translations,
    intl?.locale,
    intl?.defaultLocale,
    label?.label ?? label?.code
  )
}

export const get_t11n_group = (label?: LocalRefGroup | null, intl?: IntlShape): string => {
  if (Object.keys(intl?.messages ?? {}).includes(`labels.${label?.group ?? ""}`))
    return intl?.formatMessage({ id: `labels.${label?.group ?? ""}` }) ?? ""

  return get_t11n(
    label?.translations,
    intl?.locale,
    intl?.defaultLocale,
    label?.label ?? label?.group
  )
}

export const mandatoryTranslations = ["fr_FR", "en_GB"] as const
export type MandatoryTranslations = {
  [k in (typeof mandatoryTranslations)[number]]: string
}
export type Translations = MandatoryTranslations & { [locale: string]: string }
