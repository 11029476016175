import { H3 } from "@/components/Typographies"
import { VerticalNavTab, VerticalNavTabs } from "@/components/VerticalNavTabs"
import { PATHS, SHARING_PATHS } from "@/routing/paths"
import { Box, Grid, Stack } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { Navigate, Route, Routes } from "react-router-dom"
import { InterBU } from "./tabs/InterBU"
import { People } from "./tabs/People"
import { Project } from "./tabs/Project"

export const Shares: React.FC = () => {
  return (
    <Grid gridTemplateColumns="300px 1fr" backgroundColor="white" h="full">
      <Stack justifyContent="space-between">
        <Box>
          <H3 pt={10} pb={6} px={6}>
            <FormattedMessage id="sharing" />
          </H3>
          <VerticalNavTabs>
            <VerticalNavTab exact={false} to={`${PATHS.SHARING}${SHARING_PATHS.INTER_BU}`}>
              <FormattedMessage id="sharing.interbu" />
            </VerticalNavTab>
            <VerticalNavTab exact={false} to={`${PATHS.SHARING}${SHARING_PATHS.PEOPLE}`}>
              <FormattedMessage id="sharing.people" />
            </VerticalNavTab>
            <VerticalNavTab exact={false} to={`${PATHS.SHARING}${SHARING_PATHS.PROJECT}`}>
              <FormattedMessage id="sharing.project" />
            </VerticalNavTab>
          </VerticalNavTabs>
        </Box>
      </Stack>

      <Box p={10} backgroundColor="grey.100" h="full" overflow="auto">
        <Routes>
          <Route
            index
            element={<Navigate to={`${PATHS.SHARING}${SHARING_PATHS.INTER_BU}`} replace />}
          />
          <Route path={SHARING_PATHS.INTER_BU} element={<InterBU />} />
          <Route path={SHARING_PATHS.PEOPLE} element={<People />} />
          <Route path={SHARING_PATHS.PROJECT} element={<Project />} />
        </Routes>
      </Box>
    </Grid>
  )
}
