import { getDisplayedName as getDisplayedPeopleName } from "@/domain/people/getDisplayedName"
import type {
  PeopleJsonldReadItemPeople,
  SharedDataJsonldReadCollectionSharedData,
} from "@/networking/api"
import { SHARED_DATA_STATUS, type SHARED_DATA_STATUS_VALUE, SHARED_DATA_TYPE } from "./constants"

export const getSharedEntityName = (item: SharedDataJsonldReadCollectionSharedData): string => {
  const type = getSharedEntityType(item)
  if (type === SHARED_DATA_TYPE.PEOPLE)
    return getDisplayedPeopleName(item.people as PeopleJsonldReadItemPeople)
  if (type === SHARED_DATA_TYPE.PROJECT) return item.project?.id ?? "-" // getDisplayedName(item.project as PeopleJsonldReadItemPeople)
  if (type === SHARED_DATA_TYPE.INTER_BU) return item.interBu?.id ?? "-" // getDisplayedName(item.project as PeopleJsonldReadItemPeople)
  return item.id
}

export const getSharedEntityType = (item: SharedDataJsonldReadCollectionSharedData) => {
  if (item?.type?.code === SHARED_DATA_TYPE.PEOPLE) return SHARED_DATA_TYPE.PEOPLE
  if (item?.type?.code === SHARED_DATA_TYPE.PROJECT) return SHARED_DATA_TYPE.PROJECT
  if (item?.type?.code === SHARED_DATA_TYPE.INTER_BU) return SHARED_DATA_TYPE.INTER_BU

  return undefined
}

export const getStatusColor = (status?: string | SHARED_DATA_STATUS_VALUE) => {
  if (status === SHARED_DATA_STATUS.ACCEPTED) return "green"
  if (status === SHARED_DATA_STATUS.DENIED) return "primary.500"
  if (status === SHARED_DATA_STATUS.REVOKED) return "primary.500"
  return undefined
}
