import {
  type QueryKey,
  type UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query"
import { useMemo } from "react"

import { extractPageNumberFromNextPageRoute } from "@/utils/extractPageNumberFromNextPageRoute"
import {
  type GetCollectionData,
  getInfiniteQueryData,
  getInfiniteQueryItemsCount,
} from "@/utils/infiniteQueryDataUtils"

export const useAppInfiniteQuery = <
  TParams extends { page?: number },
  TData,
  TError = unknown,
  TQueryKey extends QueryKey = QueryKey,
>(
  getData: (params: TParams) => Promise<GetCollectionData<TData>>,
  getQueryKey: (params: TParams) => TQueryKey,
  params: TParams = {} as TParams,
  options?: Omit<
    UseInfiniteQueryOptions<
      GetCollectionData<TData>,
      TError,
      GetCollectionData<TData>,
      GetCollectionData<TData>,
      TQueryKey
    >,
    "queryKey"
  >
) => {
  const infiniteResult = useInfiniteQuery<
    GetCollectionData<TData>,
    TError,
    GetCollectionData<TData>,
    TQueryKey
  >(
    getQueryKey(params),
    ({ pageParam = "" }) => {
      const nextPage =
        typeof pageParam === "string" ? extractPageNumberFromNextPageRoute(pageParam) : undefined

      return getData({ ...params, page: nextPage })
    },
    { getNextPageParam: lastPage => lastPage["hydra:view"]?.["hydra:next"], ...options }
  )

  const totalCount = useMemo(
    () => getInfiniteQueryItemsCount(infiniteResult.data),
    [infiniteResult.data]
  )

  const flatData = useMemo(() => getInfiniteQueryData(infiniteResult.data), [infiniteResult.data])

  return { ...infiniteResult, flatData, totalCount }
}
