import { useSearchParams } from "react-router-dom"

import { SEARCH_PARAM_NAME } from "@/components/Searchbar/constants"

export const useCurrentSearchParam = () => {
  const [searchParams] = useSearchParams()

  return searchParams.get(SEARCH_PARAM_NAME) ?? undefined
}

export const useCurrentParam = (paramName: string) => {
  const [searchParams] = useSearchParams()

  return searchParams.get(paramName) ?? undefined
}
