import { Box, Center, type UseRadioProps, useFormControlContext, useRadio } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"

import { P2B } from "@/components/Typographies"

interface Props extends UseRadioProps {
  labelTranslationId: string
}
export const RadioButton: React.FC<Props> = props => {
  const { labelTranslationId: label, ...radioProps } = props
  const { getInputProps, getCheckboxProps } = useRadio(radioProps)

  const formControlContext = useFormControlContext() as
    | ReturnType<typeof useFormControlContext>
    | undefined // undefined when used outside of a form control

  const isInvalid = formControlContext?.isInvalid

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label" flexGrow="1" width="100%">
      <input {...input} />
      <Center
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderColor={isInvalid ? "red.500" : "grey.300"}
        backgroundColor={isInvalid ? "red.100" : "white"}
        color={isInvalid ? "red.600" : "grey.600"}
        _checked={{ color: "white", backgroundColor: "grey.600", borderColor: "grey.600" }}
        textAlign="center"
        p={2}
      >
        <P2B>
          <FormattedMessage id={label} />
        </P2B>
      </Center>
    </Box>
  )
}
