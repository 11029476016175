import { Flex, IconButton, Th } from "@chakra-ui/react"
import type * as CSS from "csstype"
import { useSearchParams } from "react-router-dom"

import { FilterIcon } from "@/components/Icons"
import { P3B } from "@/components/Typographies"
import { SEARCH_PARAMS } from "@/pages/Clients/constants"

export const ORDER = {
  ASC: "asc",
  DESC: "desc",
} as const

export type SortParameters =
  | "order[firstName]"
  | "order[lastName]"
  | "order[profileType]"
  | "order[businessUnit.name]"
  | "order[user.lastName]"
  | "order[createdAt]"
  | "order[updatedAt]"

type Props = React.PropsWithChildren<{
  sortParameter?: SortParameters
  align?: CSS.Property.JustifyContent
  px?: CSS.Property.Padding | number
}>

export default function Header({ children, sortParameter, align, px }: Props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const canSort = sortParameter !== undefined
  const currentSortParameter = searchParams.get(SEARCH_PARAMS.SORT_COLUMN)
  const isSortingByThisColumn = currentSortParameter === sortParameter
  const currentSortOrder = searchParams.get(SEARCH_PARAMS.ORDER)

  const handleSortButtonClick = () => {
    if (isSortingByThisColumn) {
      revertSortOrder()
    } else {
      sortByThisColumn()
    }
  }

  const revertSortOrder = () => {
    const revertedSortOrder = getRevertedSortOrder()
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set(SEARCH_PARAMS.ORDER, revertedSortOrder)
    setSearchParams(newSearchParams)
  }

  const getRevertedSortOrder = () => (currentSortOrder === ORDER.ASC ? ORDER.DESC : ORDER.ASC)

  const sortByThisColumn = () => {
    if (sortParameter !== undefined) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set(SEARCH_PARAMS.SORT_COLUMN, sortParameter)
      newSearchParams.set(SEARCH_PARAMS.ORDER, ORDER.ASC)
      setSearchParams(newSearchParams)
    }
  }

  return (
    <Th px={px}>
      <Flex alignItems="center" justifyContent={align} gap="1">
        <P3B>{children}</P3B>
        {canSort && (
          <IconButton
            aria-label="sort button"
            h="4"
            w="4"
            flexShrink="0"
            minW="0"
            borderRadius="2px"
            color={isSortingByThisColumn ? "white" : "grey.300"}
            bg={isSortingByThisColumn ? "red.500" : "transparent"}
            onClick={handleSortButtonClick}
          >
            <FilterIcon h="4" w="4" />
          </IconButton>
        )}
        {canSort && isSortingByThisColumn ? (currentSortOrder === ORDER.ASC ? "⬇️" : "⬆️") : null}
      </Flex>
    </Th>
  )
}
