import { UserJsonldUserReadItemRolesItem as UserJsonldRolesItem } from "@/networking/api"

import type { AuthenticatedUserFromJWT } from "./AuthenticatedUserFromJWT"

export class AuthenticatedUser {
  constructor(userFromJwt: AuthenticatedUserFromJWT) {
    this.id = userFromJwt.id
    this.firstname = userFromJwt.firstname ?? ""
    this.lastname = userFromJwt.lastname ?? ""
    this.username = userFromJwt.username
    this.businessUnitId = userFromJwt.businessUnitId
    this.roles = userFromJwt.roles
  }

  static createFromJWTPayload(payload?: AuthenticatedUserFromJWT): AuthenticatedUser | undefined {
    return payload === undefined ? undefined : new AuthenticatedUser(payload)
  }

  public readonly id: string
  public readonly firstname: string
  public readonly lastname: string
  public readonly username: string
  public readonly businessUnitId?: string | null
  private readonly roles: UserJsonldRolesItem[]

  get isGuru() {
    return this.roles.includes(UserJsonldRolesItem.ROLE_ADMIN)
  }

  get isInterBuSupervisor() {
    return false // this.roles.includes(UserJsonldRolesItem.ROLE_INTERBU_SUPERVISOR)
  }

  get isBuAdmin() {
    return this.roles.includes(UserJsonldRolesItem.ROLE_BU_ADMIN)
  }

  get isMarketing() {
    return false // this.roles.includes(UserJsonldRolesItem.ROLE_MARKETING)
  }

  get isFinance() {
    return false // this.roles.includes(UserJsonldRolesItem.ROLE_FINANCE)
  }

  get isCollaborator() {
    return this.roles.includes(UserJsonldRolesItem.ROLE_COLLABORATOR)
  }

  get isUser() {
    return this.roles.includes(UserJsonldRolesItem.ROLE_USER)
  }
}
