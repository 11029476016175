import { useAppInfiniteQuery } from "@/hooks/useAppInfiniteQuery"
import { useAppQuery } from "@/hooks/useAppQuery"
import {
  type GetUserSharedDatasParams,
  getGetUserSharedDatasQueryKey,
  getUserSharedDatas,
} from "@/networking/api"

const STALE_TIME_FIVE_MINUTES_IN_MS = 5 * 60 * 1000

export const useGetInfiniteQuery = (params: GetUserSharedDatasParams) => {
  const {
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    flatData: items,
    totalCount: count,
  } = useAppInfiniteQuery(getUserSharedDatas, getGetUserSharedDatasQueryKey, params, {
    staleTime: STALE_TIME_FIVE_MINUTES_IN_MS,
  })

  const isFetchingData = isLoading || isFetchingNextPage

  return { items, fetchNextPage, hasNextPage, isFetchingData, count }
}

export const useGetFirstPageQuery = (params: GetUserSharedDatasParams) => {
  const modifiedParams = {
    page: 1,
    itemsPerPage: 20,
    ...params,
  }

  const {
    isLoading: isLoadingItems,
    isFetching: isFetchingItems,
    flatData: items,
    totalCount: count,
  } = useAppQuery(getUserSharedDatas, getGetUserSharedDatasQueryKey, modifiedParams, {
    staleTime: STALE_TIME_FIVE_MINUTES_IN_MS,
  })

  const isLoading = isLoadingItems || isFetchingItems

  return { items, isLoading, count }
}
