import { LinkButtonWithIcon } from "@/components/Buttons/LinkButtonWithIcon"
import { EyeIcon } from "@/components/Icons"
import SingleField from "@/components/Sections/SingleField"
import { H4, P3 } from "@/components/Typographies"
import { getClusterLinkSheet } from "@/domain/cluster/getClusterLinkSheet"
import type { ClusterJsonldReadCollectionSharedData } from "@/networking/api"
import { Box } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

type Props = {
  cluster?: ClusterJsonldReadCollectionSharedData
}

export const ReceiverSection = ({ cluster }: Props) => {
  const intl = useIntl()
  const navigateTo = useNavigate()

  return (
    <>
      <H4 color="primary.500">
        <FormattedMessage id="userSharedData.collaboratorTo.details" />
      </H4>
      <Box w="100%">
        <SingleField
          label={intl.formatMessage({ id: "userSharedData.clusterTo.name" })}
          flexDir="column"
        >
          <P3>
            {cluster?.name}{" "}
            <LinkButtonWithIcon
              display="inline-block"
              icon={EyeIcon}
              onClick={() => navigateTo(getClusterLinkSheet(cluster))}
            />
          </P3>
        </SingleField>
      </Box>
    </>
  )
}
