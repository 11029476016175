import { IconButton } from "@chakra-ui/react"

import { SearchIcon } from "@/components/Icons"

type Props = { ariaLabel: string }

export const SearchButton: React.FC<Props> = ({ ariaLabel }) => (
  <IconButton aria-label={ariaLabel} boxSize={14} type="submit" flexShrink="0">
    <SearchIcon boxSize="4" />
  </IconButton>
)
