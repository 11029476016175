import { type TableCellProps, Td } from "@chakra-ui/react"

import { P3 } from "@/components/Typographies"

export default function TextCell({ children, ...cellProps }: TableCellProps) {
  return (
    <Td {...cellProps}>
      <P3>{children}</P3>
    </Td>
  )
}
