import { Box, Flex } from "@chakra-ui/react"
import type { ComponentProps } from "react"

type Props = {
  nbOfLines?: number
} & ComponentProps<typeof Flex>

export default function ConfidentialLines({ nbOfLines = 1, ...flexProps }: Props) {
  const lines = Array(nbOfLines).fill("•")

  return (
    <Flex direction="column" gap="4" minW="160px" {...flexProps}>
      {lines.map((line, index) => (
        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
        <Box h="4" borderRadius="9px" key={index} bg="grey.200" />
      ))}
    </Flex>
  )
}
