import type {
  CollaboratorJsonld,
  CollaboratorJsonldReadCollectionCollaborator,
  CollaboratorJsonldReadItemCollaboratorItem,
  UserJsonldReadCollectionSharedData,
} from "@/networking/api"
import { type LocalRefLabel, get_t11n_label } from "@/utils/i18n/t11n"
import { isBlank, isNotNullNorUndefined, isNullOrUndefined, ucfirst } from "@/utils/ts-utils"
import type { IntlShape } from "react-intl"
import type { RelatedCollaborator } from "./constants"

type CollaboratorType =
  | CollaboratorJsonld
  | CollaboratorJsonldReadItemCollaboratorItem
  | CollaboratorJsonldReadCollectionCollaborator
  | RelatedCollaborator
  | (UserJsonldReadCollectionSharedData & { title?: LocalRefLabel; fullname?: string })

export const getDisplayedName = (
  collaborator?: CollaboratorType,
  dft?: string,
  displayIsDefault?: boolean
): string => {
  const full = isNullOrUndefined(collaborator?.fullname)
    ? undefined
    : ucfirst(collaborator?.fullname ?? "")
  const fn = isNullOrUndefined(collaborator?.firstname)
    ? undefined
    : ucfirst(collaborator?.firstname ?? "")
  const ln = isNullOrUndefined(collaborator?.lastname)
    ? undefined
    : collaborator?.lastname.toUpperCase()
  const mark =
    (displayIsDefault &&
      isNotNullNorUndefined(collaborator) &&
      "isDefault" in collaborator &&
      collaborator.isDefault) ??
    false

  if (isBlank(fn) && isBlank(ln) && isBlank(full)) return dft ?? ""

  if (isBlank(fn) && isBlank(ln)) return full ?? dft ?? ""

  return `${fn ?? ""} ${ln ?? ""}`.trim() + (mark ? "*" : "")
}

export const getDisplayedFullname = (
  collaborator?: CollaboratorType,
  intl?: IntlShape,
  dft?: string,
  displayIsDefault?: boolean
): string => {
  const g = get_t11n_label(collaborator?.title as LocalRefLabel, intl)

  const full = isNullOrUndefined(collaborator?.fullname)
    ? undefined
    : ucfirst(collaborator?.fullname ?? "")
  const fn = isNullOrUndefined(collaborator?.firstname)
    ? undefined
    : ucfirst(collaborator?.firstname ?? "")
  const ln = isNullOrUndefined(collaborator?.lastname)
    ? undefined
    : collaborator?.lastname.toUpperCase()
  const mark =
    (displayIsDefault &&
      isNotNullNorUndefined(collaborator) &&
      "isDefault" in collaborator &&
      collaborator.isDefault) ??
    false

  if (isBlank(fn) && isBlank(ln) && isBlank(full)) return dft ?? ""

  if (isBlank(fn) && isBlank(ln)) return full ?? dft ?? ""

  return `${g} ${fn ?? ""} ${ln ?? ""}`.trim() + (mark ? "*" : "")
}

export const getCollaboratorsDisplayedNames = (
  collaborators?: CollaboratorType[],
  dft?: string,
  displayIsDefault?: boolean
) => {
  return collaborators?.map(c => getDisplayedName(c, "", displayIsDefault)).join(", ") ?? dft ?? ""
}
