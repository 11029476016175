import { LinkButtonWithIcon } from "@/components/Buttons/LinkButtonWithIcon"
import FormattedEmail from "@/components/FormattedEmail"
import { EyeIcon } from "@/components/Icons"
import SingleField from "@/components/Sections/SingleField"
import { H4, P3 } from "@/components/Typographies"
import { getClusterLinkSheet } from "@/domain/cluster/getClusterLinkSheet"
import { getCollaboratorLinkSheet } from "@/domain/collaborator/getCollaboratorLinkSheet"
import { getDisplayedName as getDisplayedCollaboratorName } from "@/domain/collaborator/getDisplayedName"
import type {
  ClusterJsonldReadCollectionSharedData,
  SharedDataJsonldReadCollectionSharedDataTo,
  UserJsonldReadCollectionSharedData,
} from "@/networking/api"
import { isNotNullNorUndefined } from "@/utils/ts-utils"
import { Box } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

type Props = {
  collaborator?: UserJsonldReadCollectionSharedData | SharedDataJsonldReadCollectionSharedDataTo
  cluster?: ClusterJsonldReadCollectionSharedData
}

export const RequestorSection = ({ collaborator, cluster }: Props) => {
  const intl = useIntl()
  const navigateTo = useNavigate()

  return (
    <>
      <H4 color="primary.500">
        <FormattedMessage id="userSharedData.collaboratorFrom.details" />
      </H4>
      <Box w="100%">
        {isNotNullNorUndefined(collaborator) && (
          <SingleField
            label={intl.formatMessage({ id: "userSharedData.collaboratorFrom.name" })}
            flexDir="column"
          >
            <P3>
              {getDisplayedCollaboratorName(collaborator)}
              <LinkButtonWithIcon
                display="inline-block"
                icon={EyeIcon}
                onClick={() => navigateTo(getCollaboratorLinkSheet(collaborator))}
              />
            </P3>
            <P3>
              <FormattedEmail email={collaborator?.email} />
            </P3>
          </SingleField>
        )}
        <SingleField
          label={intl.formatMessage({ id: "userSharedData.clusterFrom.name" })}
          flexDir="column"
        >
          <P3>
            {cluster?.name}{" "}
            <LinkButtonWithIcon
              display="inline-block"
              icon={EyeIcon}
              onClick={() => navigateTo(getClusterLinkSheet(cluster))}
            />
          </P3>
        </SingleField>
      </Box>
    </>
  )
}
