import { Table, Tbody, Thead, Tr, useDisclosure } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"

import type { SharedDataJsonldReadCollectionSharedData } from "@/networking/api"
import Header from "@/pages/Clients/components/ResultsTable/components/Header"

import ShareAcceptModal from "@/components/Sharing/ShareAcceptModal"
import ShareDeclineModal from "@/components/Sharing/ShareDeclineModal"
import ShareResponseModal from "@/components/Sharing/ShareResponseModal"
import type { SHARED_DATA_TYPE_VALUE } from "@/domain/sharedData/constants"
import { isNotNullNorUndefined } from "@/utils/ts-utils"
import { useState } from "react"
import { SharedTableRow } from "./SharedTableRow"

type Props = {
  items?: SharedDataJsonldReadCollectionSharedData[]
  type: SHARED_DATA_TYPE_VALUE
  fromOrTo: "from" | "to"
}

const getItemById = (items: SharedDataJsonldReadCollectionSharedData[], id: string) => {
  for (const item of items) {
    if (item.id === id) return item
  }
  return {} as SharedDataJsonldReadCollectionSharedData
}

export const SharedTable: React.FC<Props> = ({ items, type, fromOrTo }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isOpen: isOpenAccept, onOpen: onOpenAccept, onClose: onCloseAccept } = useDisclosure()
  const { isOpen: isOpenDecline, onOpen: onOpenDecline, onClose: onCloseDecline } = useDisclosure()

  const [currentItem, setCurrentItem] = useState<SharedDataJsonldReadCollectionSharedData>(
    {} as SharedDataJsonldReadCollectionSharedData
  )
  const [isRevoke, setIsRevoke] = useState(false)
  const onOpenRevoke = () => {
    setIsRevoke(true)
    onOpenDecline()
  }

  const openRow = (id: string) => {
    setCurrentItem(getItemById(items ?? [], id))
    if (isNotNullNorUndefined(currentItem)) onOpen()
  }

  return (
    <>
      <Table variant="simple" color="grey.600">
        <Thead>
          <Tr>
            <Header>
              <FormattedMessage id={`sharing.${type}.type`} />
            </Header>
            <Header>
              <FormattedMessage id="sharing.sharedItem.name" />
            </Header>
            <Header>
              <FormattedMessage id="sharing.sharedItem.cluster" />
            </Header>
            <Header>
              <FormattedMessage id="sharing.sharedItem.createdAt" />
            </Header>
            <Header>
              <FormattedMessage id="sharing.sharedItem.responseAt" />
            </Header>
            <Header>
              <FormattedMessage id="sharing.sharedItem.status" />
            </Header>
          </Tr>
        </Thead>
        <Tbody>
          {items?.map(item => (
            <SharedTableRow item={item} key={item.id} fromOrTo={fromOrTo} onOpen={openRow} />
          ))}
        </Tbody>
      </Table>

      <ShareResponseModal
        isOpen={isOpen}
        onClose={onClose}
        sharedEntity={currentItem}
        onOpenAccept={onOpenAccept}
        onOpenDecline={onOpenDecline}
        onOpenRevoke={onOpenRevoke}
      />
      <ShareAcceptModal isOpen={isOpenAccept} onClose={onCloseAccept} sharedEntity={currentItem} />
      <ShareDeclineModal
        isOpen={isOpenDecline}
        onClose={onCloseDecline}
        sharedEntity={currentItem}
        isRevoke={isRevoke}
      />
    </>
  )
}
