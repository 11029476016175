export const SHARED_DATA_STATUS = {
  ASKED: "asked",
  ACCEPTED: "accepted",
  DENIED: "denied",
  REVOKED: "revoked",
} as const
export type SHARED_DATA_STATUS = typeof SHARED_DATA_STATUS
export type SHARED_DATA_STATUS_VALUE = SHARED_DATA_STATUS[keyof SHARED_DATA_STATUS]

export const SHARED_DATA_TYPE = {
  PEOPLE: "people",
  PROJECT: "project",
  INTER_BU: "interbu",
}
export type SHARED_DATA_TYPE = typeof SHARED_DATA_TYPE
export type SHARED_DATA_TYPE_VALUE = SHARED_DATA_TYPE[keyof SHARED_DATA_TYPE]
