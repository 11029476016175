import { FullLoader } from "@/components/FullLoader"
import { DownloadIcon } from "@/components/Icons"
import { H5, P2, P2B, P3 } from "@/components/Typographies"
import { ROOT_PATH } from "@/domain/resources/constants"
import { useAccessToken } from "@/hooks/useAccessToken"
import { useListFile, useListFolder } from "@/networking/api"
import { getFileLink } from "@/utils/download"
import { formatFileSize, getFilename } from "@/utils/files/file"
import { isBlank } from "@/utils/ts-utils"
import { Box, Card, Divider, Grid, GridItem, Link } from "@chakra-ui/react"
import { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

const trimSlashes = (path?: string) => {
  return (path ?? "").replace(/(^\/+|\/+$)/, "")
}

const BreadCrumb = ({
  currentPath,
  setPath,
}: { currentPath: string; setPath: (str: string) => void }) => {
  const intl = useIntl()

  let link = ROOT_PATH
  const nodes = trimSlashes(currentPath).split("/")
  const rootNode = nodes.shift()
  const links = [
    {
      label: `${intl.formatMessage({ id: "resources.breadcrumb.root" })} (${trimSlashes(rootNode)})`,
      link,
    },
  ]

  for (const node of nodes) {
    if (isBlank(node)) continue

    link += `/${node}`
    links.push({
      label: node,
      link,
    })
  }

  return (
    <>
      {links.map((n, idx) => (
        <P2 key={`${idx + 0}`}>
          {idx < 1 ? "📂 " : " ▸ "}
          <Link onClick={() => setPath(n.link)}>{n.label}</Link>
        </P2>
      ))}
    </>
  )
}

export const FileExplorer: React.FC = () => {
  const { accessToken } = useAccessToken()
  const [path, setPath] = useState(ROOT_PATH)

  const { data: dataFolders, isFetching: isDataFolderFecthing } = useListFolder(path)
  const listFolders = dataFolders?.["hydra:member"] ?? []

  const { data: dataFiles, isFetching: isDataFileFecthing } = useListFile(path)
  const listFiles = dataFiles?.["hydra:member"] ?? []

  const isLoading = isDataFolderFecthing || isDataFileFecthing

  /*
  const downloadMedia = (file: MediaJsonld) => {
    const f = downloadFile(file.id)
  }
  */

  return (
    <>
      <Box flexDir="column" gap="6">
        <BreadCrumb currentPath={path} setPath={setPath} />
      </Box>

      <Divider />

      {isLoading && (
        <Box>
          <FullLoader />
        </Box>
      )}

      {!isLoading && listFiles.length < 1 && listFolders.length < 1 && (
        <FormattedMessage id="resources.folderEmpty" />
      )}

      {!isLoading && listFolders.length > 0 && (
        <>
          <H5>
            <FormattedMessage id="resources.documents.folders" />
          </H5>
          <Grid gap={6} templateColumns="repeat(5, 1fr)">
            {listFolders.map(folder => (
              <GridItem key={folder.id}>
                <Card p={4} onClick={() => setPath(folder.path ?? ROOT_PATH)} cursor="pointer">
                  {`📂 ${getFilename(folder.path)}/`}
                </Card>
              </GridItem>
            ))}
          </Grid>
        </>
      )}

      {!isLoading && listFiles.length > 0 && listFolders.length > 0 && <Divider />}

      {!isLoading && listFiles.length > 0 && (
        <>
          <H5>
            <FormattedMessage id="resources.documents.files" />
          </H5>
          <Grid gap={6} templateColumns="repeat(4, 1fr)">
            {listFiles.map(file => (
              <GridItem key={file.id}>
                <Box position="relative" pl="2rem">
                  <DownloadIcon position="absolute" left="0" boxSize={4} mt={1} />
                  <Link href={getFileLink(file, accessToken)} display="block">
                    <P2B>{getFilename(file.path)}</P2B>
                  </Link>{" "}
                  <P3 color="gray.500" display="block">
                    ({file.type}, {formatFileSize(Number.parseInt(file.size ?? "0"))})
                  </P3>
                </Box>
              </GridItem>
            ))}
          </Grid>
        </>
      )}
    </>
  )
}
