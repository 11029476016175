import type { MediaJsonld } from "@/networking/api"
import { isNotBlank } from "./ts-utils"

export const download = (filename: string, text: string) => {
  const element = document.createElement("a")
  element.setAttribute("href", `data:text/plain;charset=utf-8, ${encodeURIComponent(text)}`)
  element.setAttribute("download", filename)

  element.style.display = "none"
  document.body.appendChild(element)

  element.click()
  document.body.removeChild(element)
}

export const getFileLink = (file: MediaJsonld, token?: string) => {
  const fileURL = new URL(file.source ?? "")
  if (fileURL.protocol === "http:") fileURL.protocol = "https:"
  if (isNotBlank(token)) fileURL.searchParams.set("token", token)
  return fileURL.href
}

export const linkToFile = (file: MediaJsonld, token?: string) => {
  const fileURL = new URL(file.source ?? "")
  if (isNotBlank(token)) fileURL.searchParams.set("token", token)

  console.log(fileURL.href)
  window.location.href = fileURL.href

  // window.open(fileURL.href)

  /*
  const element = document.createElement("a")
  element.setAttribute("href", fileURL.href)
  element.setAttribute("download", getFilename(file.path))

  element.style.display = "none"
  document.body.appendChild(element)

  element.click()
  document.body.removeChild(element)
  */
}
