import { Suspense, lazy } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router-dom"

import { AppRefLabelsContext } from "@/context/AppRefLabelsContext"
import AuthenticatedUserContextProvider from "@/context/AuthenticatedUserContext/AuthenticatedUserContext"

import { FullLoader } from "@/components/FullLoader"
import { Layout } from "@/components/Layout"
import AuthWall from "@/components/RoutingWalls/AuthWall"
import { GuruWall } from "@/components/RoutingWalls/GuruWall"

import { Resources } from "@/pages/Resources"
import { Shares } from "@/pages/Shares"
import { AUTH_PATHS, PATHS, PROJECT_PATHS } from "./paths"

const NotFound = lazy(() => import("@/pages/NotFound"))
const Logout = lazy(() => import("@/pages/Auth/Logout"))
const Dashboard = lazy(() => import("@/pages/Dashboard"))
const Clients = lazy(() => import("@/pages/Clients"))
const InterBu = lazy(() => import("@/pages/InterBu"))
// const Help = lazy(() => import("@/pages/Help"))
const Parameters = lazy(() => import("@/pages/Parameters"))
const Network = lazy(() => import("@/pages/Network"))
const Customers = lazy(() => import("@/pages/People"))
const CustomerSheet = lazy(() => import("@/pages/PeopleSheet"))
const Admin = lazy(() => import("@/pages/Admin"))
const ClientSheet = lazy(() => import("@/pages/ClientSheet"))
const TProperties = lazy(() => import("@/pages/TProperties"))
const TpropertySheet = lazy(() => import("@/pages/TpropertySheet"))
const ProjectsOffers = lazy(() => import("@/pages/ProjectsOffers"))
const ProjectOfferSheet = lazy(() => import("@/pages/ProjectOfferSheet"))
const TAdmin = lazy(() => import("@/pages/TAdmin"))

export const PrivateRoutes = () => {
  return (
    <AppRefLabelsContext>
      <AuthenticatedUserContextProvider>
        <Routes>
          <Route element={<AuthWall />}>
            <Route
              element={
                <Layout>
                  <Suspense fallback={<FullLoader />}>
                    <Outlet />
                  </Suspense>
                </Layout>
              }
            >
              <Route path="*" element={<NotFound />} />
              <Route path={PATHS.DASHBOARD} element={<Dashboard />} />
              {/** TODO : Clean after propertyOffers validation */}
              <Route path={PATHS.CLIENTS}>
                <Route index element={<Clients />} />
                <Route path=":clientId/*" element={<ClientSheet />} />
              </Route>
              {/** END TODO */}
              <Route path={PATHS.CUSTOMERS}>
                <Route index path="*" element={<Customers parentPath={PATHS.CUSTOMERS} />} />
                <Route
                  path=":peopleId/*"
                  element={<CustomerSheet parentPath={PATHS.CUSTOMERS} />}
                />
              </Route>
              <Route path={PATHS.PROPERTIES}>
                <Route
                  index
                  element={<Navigate to={`${PATHS.PROPERTIES}${PROJECT_PATHS.OFFER}`} replace />}
                />
                <Route path={`${PATHS.PROPERTIES}${PROJECT_PATHS.OFFER}`}>
                  <Route index element={<ProjectsOffers />} />
                  <Route
                    path=":projectId/*"
                    element={
                      <ProjectOfferSheet parentPath={`${PATHS.PROPERTIES}${PROJECT_PATHS.OFFER}`} />
                    }
                  />
                </Route>
                {/** TODO : Clean after propertyOffers validation */}
                <Route path={`${PATHS.PROPERTIES}${PATHS.PROPERTIES_OLD}`}>
                  <Route index element={<TProperties />} />
                  <Route path=":propertyId/*" element={<TpropertySheet />} />
                </Route>
                {/** END TODO */}
              </Route>
              <Route path={`${PATHS.SHARING}/*`} element={<Shares />} />
              <Route path={`${PATHS.RESOURCES}/*`} element={<Resources />} />
              <Route path={`${PATHS.NETWORK}/*`} element={<Network />} />
              {/* <Route path={PATHS.HELP} element={<Help />} /> */}
              <Route path={`${PATHS.PARAMETERS}/*`} element={<Parameters />} />

              <Route element={<GuruWall />}>
                <Route path={`${PATHS.ADMIN}/*`} element={<Admin />} />
                <Route path={`${PATHS.TADMIN}/*`} element={<TAdmin />} />
              </Route>
            </Route>
            <Route
              path={PATHS.AUTH + AUTH_PATHS.LOGOUT}
              element={
                <Suspense fallback={<FullLoader />}>
                  <Logout />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </AuthenticatedUserContextProvider>
    </AppRefLabelsContext>
  )
}
