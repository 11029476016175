import { Button, type ButtonProps, type ComponentWithAs, type IconProps } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"

import { isNotBlank } from "@/utils/ts-utils"

type Props = {
  icon: ComponentWithAs<"svg", IconProps>
  textTranslationId?: string
} & ButtonProps

export const LinkButtonWithIcon: React.FC<Props> = ({
  icon: Icon,
  textTranslationId,
  ...props
}) => {
  return (
    <Button
      variant="link"
      display={props.display ?? "flex"}
      gap={1}
      alignItems={props.alignItems ?? "center"}
      color="primary.500"
      fontWeight="600"
      textDecoration="none"
      {...props}
    >
      {isNotBlank(textTranslationId) && <FormattedMessage id={textTranslationId} />}
      {isNotBlank(props.title) && props.title}
      <Icon boxSize={4} />
    </Button>
  )
}
