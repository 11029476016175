import { Flex, type FlexProps } from "@chakra-ui/react"
import { useForm } from "react-hook-form"
import { useSearchParams } from "react-router-dom"

import { SearchButton } from "./SearchButton"
import { SearchbarInput } from "./SearchbarInput"
import { SEARCH_PARAM_NAME } from "./constants"

type SearchFormInput = {
  searchTerm: string
}

type Props = {
  searchbarPlaceholder: string
  submitButtonAriaLabel: string
} & FlexProps

export const Searchbar: React.FC<Props> = ({
  searchbarPlaceholder,
  submitButtonAriaLabel,
  ...flexProps
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const search = searchParams.get(SEARCH_PARAM_NAME) ?? undefined

  const currentSearchTerm = search

  const { register, handleSubmit } = useForm<SearchFormInput>()

  const handleSearchSubmit = ({ searchTerm }: SearchFormInput) => {
    const newSearchParams = new URLSearchParams(searchParams as URLSearchParams)
    newSearchParams.set(SEARCH_PARAM_NAME, searchTerm)
    if (setSearchParams) setSearchParams(newSearchParams)
  }

  return (
    <Flex onSubmit={handleSubmit(handleSearchSubmit)} as="form" gap="6" {...flexProps}>
      <SearchbarInput
        placeholder={searchbarPlaceholder}
        defaultValue={currentSearchTerm}
        {...register("searchTerm")}
      />
      <SearchButton ariaLabel={submitButtonAriaLabel} />
    </Flex>
  )
}
