import { H3 } from "@/components/Typographies"
import { Flex } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"
import { FileExplorer } from "./components/FileExplorer"

export const Documents: React.FC = () => {
  return (
    <>
      <Flex flexDir="column" gap="6" px="8" py="12">
        <H3>
          <FormattedMessage id="resources.documents" />
        </H3>
      </Flex>

      <Flex flexDir="column" gap="6" px="8" py="12">
        <FileExplorer />
      </Flex>
    </>
  )
}
