import SectionColumn from "@/components/Sections/SectionColumn"
import SectionContainer from "@/components/Sections/SectionContainer"
import SingleField from "@/components/Sections/SingleField"
import { H4 } from "@/components/Typographies"
import { getSharedEntityType } from "@/domain/sharedData/getSharedUtils"
import type { SharedDataJsonldReadCollectionSharedData } from "@/networking/api"
import { FormattedMessage, useIntl } from "react-intl"

type Props = {
  entity: SharedDataJsonldReadCollectionSharedData
}

export const RequestSection = ({ entity }: Props) => {
  const intl = useIntl()

  return (
    <>
      <H4 color="primary.500">
        <FormattedMessage id="userSharedData.people.details" />
      </H4>
      <SectionContainer title="">
        <SectionColumn>
          <SingleField label={intl.formatMessage({ id: "userSharedData.entity.subject" })}>
            <FormattedMessage
              id={`userSharedData.${getSharedEntityType(entity)}.motivation.${entity.subject}`}
            />
          </SingleField>
          <SingleField label={intl.formatMessage({ id: "userSharedData.entity.message" })}>
            {entity.message}
          </SingleField>
        </SectionColumn>
      </SectionContainer>
    </>
  )
}
