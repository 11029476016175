import { chakra } from "@chakra-ui/react"
import { useIntl } from "react-intl"

import { type LocalRefLabel, get_t11n_label } from "@/utils/i18n/t11n"
import { isBlank } from "@/utils/ts-utils"
import type { ComponentProps } from "react"

type Props = {
  label?: LocalRefLabel | LocalRefLabel[] | null
  dft?: string
} & ComponentProps<typeof chakra.span>

export const RefLabel: React.FC<Props> = ({ label, dft, ...props }: Props) => {
  const intl = useIntl()

  const d = props.display ?? "inline-block"

  const local_label = Array.isArray(label)
    ? label.map(l => get_t11n_label(l, intl)).join(", ")
    : get_t11n_label(label ?? undefined, intl)

  return (
    <chakra.span display={d} {...props}>
      {isBlank(local_label) ? dft ?? "" : local_label}
    </chakra.span>
  )
}
