import { LinkButtonWithIcon } from "@/components/Buttons/LinkButtonWithIcon"
import FormattedEmail from "@/components/FormattedEmail"
import FormattedPhone from "@/components/FormattedPhone"
import { EyeIcon } from "@/components/Icons"
import MultilineField from "@/components/Sections/MultilineField"
import SectionColumn from "@/components/Sections/SectionColumn"
import SectionContainer from "@/components/Sections/SectionContainer"
import SingleField from "@/components/Sections/SingleField"
import { H4, P3 } from "@/components/Typographies"
import { getDefaultContactDetails } from "@/domain/contactdetails/getContactDetails"
import { getDisplayedName } from "@/domain/people/getDisplayedName"
import { getPeopleLinkSheet } from "@/domain/people/getPeopleLinkSheet"
import type { SharedDataJsonldReadCollectionSharedDataPeople } from "@/networking/api"
import { isNotBlank } from "@/utils/ts-utils"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

type Props = {
  people: SharedDataJsonldReadCollectionSharedDataPeople
}

export const PeopleSection = ({ people }: Props) => {
  const intl = useIntl()
  const navigateTo = useNavigate()

  const contactDetails =
    getDefaultContactDetails(people?.contactDetails)?.contactDetails ?? undefined

  return (
    <>
      <H4 color="primary.500">
        <FormattedMessage id="userSharedData.people.details" />
      </H4>
      <SectionContainer title="">
        <SectionColumn>
          <SingleField label={intl.formatMessage({ id: "userSharedData.people.name" })}>
            {getDisplayedName(people ?? undefined)}
            <LinkButtonWithIcon
              display="inline-block"
              icon={EyeIcon}
              onClick={() => navigateTo(getPeopleLinkSheet(people))}
            />
          </SingleField>
          {people?.isShared ? (
            <SingleField
              label={intl.formatMessage({ id: "userSharedData.people.contactDetails" })}
              flexDir="column"
            >
              {isNotBlank(contactDetails?.email) && (
                <P3>
                  <FormattedEmail email={contactDetails?.email} />
                </P3>
              )}
              {isNotBlank(contactDetails?.phone) && (
                <P3>
                  <FormattedPhone phone={contactDetails?.phone} />
                </P3>
              )}
              {isNotBlank(contactDetails?.phone2) && (
                <P3>
                  <FormattedPhone phone={contactDetails?.phone2} />
                </P3>
              )}
              {isNotBlank(contactDetails?.mobile) && (
                <P3>
                  <FormattedPhone phone={contactDetails?.mobile} />
                </P3>
              )}
            </SingleField>
          ) : (
            <MultilineField
              label={intl.formatMessage({ id: "userSharedData.people.contactDetails" })}
              isConfidential={true}
            />
          )}
        </SectionColumn>
      </SectionContainer>
    </>
  )
}
