import { useFormatDate } from "@/hooks/useFormatDate"
import { isNotNullNorUndefined } from "@/utils/ts-utils"

type Props = {
  date?: string | null
  includeTime?: boolean
  dft?: string
}

export default function FormattedDate({ date, includeTime, dft }: Props) {
  const formatDate = useFormatDate({ includeTime })

  return isNotNullNorUndefined(date) ? <>{formatDate(date)}</> : <>{dft ?? ""}</>
}
