import type { FetchNextPageOptions } from "@tanstack/react-query"
import { FormattedMessage } from "react-intl"

import { FullLoader } from "@/components/FullLoader"
import { IntersectSpy } from "@/components/IntersectSpy"
import type { SharedDataJsonldReadCollectionSharedData } from "@/networking/api"

type Props = React.PropsWithChildren<{
  currentSearchTerm?: string
  items?: SharedDataJsonldReadCollectionSharedData[]
  isFetchingData: boolean
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<unknown>
  hasNextPage?: boolean
}>

export const TableLayout: React.FC<Props> = ({
  currentSearchTerm,
  items,
  isFetchingData,
  fetchNextPage,
  hasNextPage,
  children,
}) => {
  const onIntersect = () => {
    if (!isFetchingData && hasNextPage) {
      void fetchNextPage()
    }
  }

  const hasResult = items?.length !== 0
  const hasSearchParam = currentSearchTerm !== "" && currentSearchTerm !== undefined

  return (
    <div>
      {hasResult && children}
      {!hasResult && hasSearchParam && (
        <FormattedMessage id="search.noResult" values={{ currentSearchTerm }} />
      )}
      {!hasResult && !hasSearchParam && <FormattedMessage id="noResult" />}
      {isFetchingData && <FullLoader noAppearDelay={items !== undefined} />}
      {!isFetchingData && <IntersectSpy onIntersect={onIntersect} />}
    </div>
  )
}
