import { useIntersectionObserver } from "@react-hookz/web"
import { useEffect, useRef } from "react"

type Props = React.PropsWithChildren<{
  onIntersect: () => void
}>

export const IntersectSpy: React.FC<Props> = ({ onIntersect }) => {
  const rootRef = useRef<HTMLDivElement>(null)
  const spyDivRef = useRef<HTMLDivElement>(null)

  const intersection = useIntersectionObserver(spyDivRef, { root: rootRef })
  const isIntersecting = intersection?.isIntersecting ?? false

  useEffect(() => {
    if (isIntersecting) onIntersect()
  }, [isIntersecting, onIntersect])

  return <div ref={spyDivRef} />
}
