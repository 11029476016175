import { Link } from "@chakra-ui/react"

import { isNotNullNorUndefined } from "@/utils/ts-utils"
import { EnvelopeIcon } from "../Icons"
import { P3 } from "../Typographies"

type Props = {
  email?: string | null
  dft?: string
  smaller?: boolean
  withIcon?: boolean
}

export default function FormattedEmail({ email, dft, smaller, withIcon }: Props) {
  const Element = () =>
    isNotNullNorUndefined(email) ? (
      <Link href={`mailto:${email}`} target="_blank">
        {smaller ? <P3>{email}</P3> : email}
      </Link>
    ) : (
      <>{dft ?? ""}</>
    )

  return withIcon ? (
    <>
      <EnvelopeIcon boxSize="4" color="grey.300" />
      <Element />
    </>
  ) : (
    <Element />
  )
}
