export const SEARCH_PARAMS = {
  SORT_COLUMN: "sortcolumn",
  ORDER: "order",
  SEARCH: "search",
  LAYOUT: "layout",
} as const

export const LAYOUT_TYPES = {
  CARDS: "cards",
  TABLE: "table",
} as const

export type LayoutType = (typeof LAYOUT_TYPES)[keyof typeof LAYOUT_TYPES]
