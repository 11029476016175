import type { IntlShape } from "react-intl"

import type { PeopleJsonldReadItemPeople } from "@/networking/api"
import { get_t11n_label } from "@/utils/i18n/t11n"
import { isBlank, isNullOrUndefined, ucfirst } from "@/utils/ts-utils"

export const getDisplayedName = (
  people?: PeopleJsonldReadItemPeople,
  dft?: string,
  intl?: IntlShape
): string => {
  const g = ucfirst(get_t11n_label(people?.title, intl))

  const fn = isNullOrUndefined(people?.firstname) ? undefined : ucfirst(people?.firstname ?? "")
  const ln = isNullOrUndefined(people?.lastname) ? undefined : people?.lastname.toUpperCase()

  if (isBlank(fn) && isBlank(ln)) return dft ?? ""

  return `${g} ${fn ?? ""} ${ln ?? ""}`.trim()
}
