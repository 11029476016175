import type {
  ContactDetailsJsonldReadItemAgencyItem,
  ContactDetailsJsonldReadItemClusterItem,
  ContactDetailsJsonldReadItemCollaboratorItem,
  ContactDetailsJsonldReadItemPeople,
  ContactDetailsJsonldReadItemProject,
  ContactDetailsJsonldReadItemProperty,
  ContactDetailsJsonldReadItemPropertySearch,
  ContactDetailsJsonldReadItemSocietyItem,
} from "@/networking/api"
import type { LocalRefLabel } from "@/utils/i18n/t11n"
import {
  convertPropertiesToStringType,
  isBlank,
  isNullOrUndefined,
  updateObjectWith,
} from "@/utils/ts-utils"
import type { Prettify, PropType } from "@/utils/types/utils"
import type { TimeTableData } from "./timetables"

type RelInfo = {
  isDefault?: boolean
  order?: number
}

type NotNullRelInfo = Prettify<{ [K in keyof RelInfo]: NonNullable<RelInfo[K]> }>

type Rel<T> = {
  id: string
  contactDetails?: T
} & RelInfo

type ExtendedRel<T> = Prettify<T & RelInfo>

export type ContactDetailsGet = Prettify<
  ContactDetailsJsonldReadItemAgencyItem &
    ContactDetailsJsonldReadItemClusterItem &
    ContactDetailsJsonldReadItemCollaboratorItem &
    ContactDetailsJsonldReadItemPeople &
    ContactDetailsJsonldReadItemProject &
    ContactDetailsJsonldReadItemProperty &
    ContactDetailsJsonldReadItemPropertySearch &
    ContactDetailsJsonldReadItemSocietyItem
>

export type RelContactDetailsGet = Rel<ContactDetailsGet>
export type ExtendedRelContactDetailsGet = ExtendedRel<ContactDetailsGet>

type TimeTables = PropType<ContactDetailsGet, "timetables">

export type ContactDetailsFormDefaults = Prettify<
  {
    [key in keyof Omit<ExtendedRelContactDetailsGet, "timetables" | "canton">]: string | undefined
  } & {
    timetables?: TimeTableData
    canton?: LocalRefLabel
  }
>

export type ContactDetailsStepForm = {
  contactDetails: ContactDetailsFormDefaults[]
}

export const getRelatedContactDetail = <TContactDetails>(rel: Rel<TContactDetails>) => {
  return {
    ...rel.contactDetails,
    isDefault: "isDefault" in rel ? rel.isDefault : undefined,
    order: "order" in rel ? rel.order : undefined,
  } as ExtendedRel<TContactDetails>
}

export const getRelatedContactDetails = <TContactDetails>(rels?: Rel<TContactDetails>[]) => {
  return isNullOrUndefined(rels) ? undefined : rels.map(rel => getRelatedContactDetail(rel))
}

export const getDefaultRelatedContactDetails = <TContactDetails>(rels?: Rel<TContactDetails>[]) => {
  for (const rel of rels ?? []) {
    if (rel.isDefault) return getRelatedContactDetail(rel)
  }

  const v = rels?.[0]
  return isNullOrUndefined(v) ? undefined : getRelatedContactDetail(v)
}

export const getDefaultContactDetails = <
  T extends ContactDetailsGet | ExtendedRel<ContactDetailsGet>,
>(
  contactDetails?: T[]
): T | undefined => {
  if (isNullOrUndefined(contactDetails) || contactDetails.length < 1) return undefined

  let id_max_order = 0

  for (let k = 0; k < contactDetails.length; k++) {
    const contactDetail = contactDetails[k] as ExtendedRel<ContactDetailsGet>
    if (contactDetail.isDefault) return contactDetail as T
    if (((contactDetail.order ?? 0) as number) > id_max_order) id_max_order = k
  }

  return contactDetails[id_max_order] as T
}

export const getDefaultContactDetailsIndex = <
  T extends ContactDetailsGet | ExtendedRel<ContactDetailsGet> | ContactDetailsFormDefaults,
>(
  contactDetails?: T[]
): number => {
  let id_max_order = 0
  if (isNullOrUndefined(contactDetails) || contactDetails.length < 1) return id_max_order

  for (let k = 0; k < contactDetails.length; k++) {
    const contactDetail = contactDetails[k] as ExtendedRel<ContactDetailsGet>
    if (contactDetail.isDefault) return k
    if (((contactDetail.order ?? 0) as number) > id_max_order) id_max_order = k
  }

  return id_max_order
}

export const hasContactDetails = <T extends ContactDetailsGet | ExtendedRel<ContactDetailsGet>>(
  contact: T
) => {
  for (const prop in contact) {
    if (
      isNullOrUndefined(contact[prop]) ||
      (typeof contact[prop] === "string" && isBlank(contact[prop] as string)) ||
      (Array.isArray(contact[prop]) && (contact[prop] as unknown[]).length < 1)
    )
      continue
  }
  return true
}

export const contact_details_form_defaults: ContactDetailsFormDefaults = {
  id: "",
  order: "0",
  isDefault: "false",
  address1: "",
  address2: "",
  address3: "",
  region: "",
  zipcode: "",
  city: "",
  country: "",
  longitude: "",
  latitude: "",
  neighborhoodLongitude: "",
  neighborhoodLatitude: "",
  publicLongitude: "",
  publicLatitude: "",
  altitude: "",
  canton: undefined,
  radius: "",
  neighborhood: "",
  phone: "",
  phone2: "",
  phoneWww: "",
  mobile: "",
  mobile2: "",
  fax: "",
  email: "",
  emailFallback: "",
  emailLeads: "",
  emailRedir: "",
  url: "",
  timetables: undefined,
  socialInstagram: "",
  socialLinkedin: "",
  socialTwitter: "",
  socialFacebook: "",
  socialYoutube: "",
}

export const contact_details_props = {
  id: "" as string,
  address1: "" as string | undefined,
  address2: "" as string | undefined,
  address3: "" as string | undefined,
  region: "" as string | undefined,
  zipcode: "" as string | undefined,
  city: "" as string | undefined,
  country: "" as string | undefined,
  longitude: 0 as number | undefined,
  latitude: 0 as number | undefined,
  neighborhoodLongitude: 0 as number | undefined,
  neighborhoodLatitude: 0 as number | undefined,
  publicLatitude: 0 as number | undefined,
  publicLongitude: 0 as number | undefined,
  altitude: 0 as number | undefined,
  canton: undefined as LocalRefLabel | undefined,
  radius: 0 as number | undefined,
  neighborhood: "" as string | undefined,
  phone: "" as string | undefined,
  phone2: "" as string | undefined,
  phoneWww: "" as string | undefined,
  mobile: "" as string | undefined,
  mobile2: "" as string | undefined,
  fax: "" as string | undefined,
  email: "" as string | undefined,
  emailFallback: "" as string | undefined,
  emailLeads: "" as string | undefined,
  emailRedir: "" as string | undefined,
  url: "" as string | undefined,
  timetables: undefined as TimeTables | undefined,
  socialInstagram: "" as string | undefined,
  socialLinkedin: "" as string | undefined,
  socialTwitter: "" as string | undefined,
  socialFacebook: "" as string | undefined,
  socialYoutube: "" as string | undefined,
  // createdAt: "" as string | undefined,
  // updatedAt: "" as string | undefined,
} as const

export type ContactDetailsProps = typeof contact_details_props
export type RelContactDetailsProps = Rel<ContactDetailsProps>

export const contact_details_keys = Object.keys(contact_details_props) as ReadonlyArray<
  keyof ContactDetailsProps
>

export const convertContactDetailsRead2Form = (values: Partial<ExtendedRelContactDetailsGet>) => {
  // console.log("convert:", values, contact_details_form_defaults)
  return {
    ...(updateObjectWith(contact_details_form_defaults, {
      ...convertPropertiesToStringType(values, {
        exclude: ["timetables", "canton"],
      }),
      timetables: (values.timetables as TimeTableData) ?? undefined,
      canton: (values.canton as LocalRefLabel) ?? undefined,
    }) as ContactDetailsFormDefaults),
  }
}

export const convertContactDetailsForm2Post = (values: ContactDetailsFormDefaults) => {
  return {
    ...convertPropertiesToStringType(
      {
        ...contact_details_form_defaults,
        ...values,
      },
      { exclude: ["id", "timetables", "canton"] }
    ),
    timetables: (values.timetables as unknown) ?? undefined,
    canton: values.canton?.id ?? undefined,
  }
}

export const convertContactDetailsForm2Patch = (values: ContactDetailsFormDefaults) => {
  return {
    ...convertPropertiesToStringType(
      {
        ...contact_details_form_defaults,
        ...values,
      },
      { exclude: ["timetables", "canton"] }
    ),
    timetables: (values.timetables as unknown) ?? undefined,
    canton: values.canton?.id ?? undefined,
  }
}
