import { Searchbar } from "@/components/Searchbar"
import { SEARCH_BAR_DEFAULT_MAX_WIDTH } from "@/components/Searchbar/constants"
import { H3 } from "@/components/Typographies"
import { useAuthenticatedUserContext } from "@/context/AuthenticatedUserContext"
// import { getDefaultCollaborator } from "@/domain/collaborator/getRelatedCollaborator"
import { SHARED_DATA_TYPE } from "@/domain/sharedData/constants"
import { useCurrentSearchParam } from "@/hooks/useCurrentSearchParam"
import { useGetUser } from "@/networking/api"
import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"
import { useSearchParams } from "react-router-dom"
import { SharedTable } from "./components/Table/SharedTable"
import { TableLayout } from "./components/TableLayout/TableLayout"
import { useGetInfiniteQuery } from "./components/TableLayout/hook/useGetInfiniteQuery"

export const Project: React.FC = () => {
  const intl = useIntl()

  const { user } = useAuthenticatedUserContext()
  // const isAuthenticatedAsBuAdmin = user?.isBuAdmin
  const { data: userInfos } = useGetUser(user?.id ?? "")
  // const collaborator_dft = getDefaultCollaborator(userInfos?.collaborators)

  const [searchParams, setSearchParams] = useSearchParams()
  const currentSearchTerm = useCurrentSearchParam()

  const {
    items: requestSharedItems,
    fetchNextPage: fetchNextRequestSharedItemsPage,
    hasNextPage: hasNextRequestSharedItemsPage,
    isFetchingData: isFetchingRequestSharedItemsData,
    // count: requestSharedItemsCount,
  } = useGetInfiniteQuery({
    search: currentSearchTerm,
    "fromCluster.collaborator.collaborator.users.id": userInfos?.id,
    "type.code": SHARED_DATA_TYPE.PROJECT,
  })

  const {
    items: responseSharedItems,
    fetchNextPage: fetchNextResponseSharedItemsPage,
    hasNextPage: hasNextResponseSharedItemsPage,
    isFetchingData: isFetchingResponseSharedItemsData,
    // count: responseSharedItemsCount,
  } = useGetInfiniteQuery({
    search: currentSearchTerm,
    "toCluster.collaborator.collaborator.users.id": userInfos?.id,
    "type.code": SHARED_DATA_TYPE.PROJECT,
  })

  return (
    <Flex flexDir="column" gap="6" px="8" py="12">
      <H3>
        <FormattedMessage id="sharing.project.title" />
      </H3>

      <Flex gap="8">
        <Searchbar
          searchbarPlaceholder={intl.formatMessage({
            id: "sharing.search.placeholder",
          })}
          submitButtonAriaLabel={intl.formatMessage({
            id: "sharing.search.button.ariaLabel",
          })}
          maxW={SEARCH_BAR_DEFAULT_MAX_WIDTH}
          flexGrow={1}
        />
      </Flex>

      <Tabs variant="line" isLazy>
        <TabList>
          <Tab>
            <FormattedMessage id="sharing.project.sent" /> {/* <ListCount count={saleCount} /> */}
          </Tab>
          <Tab>
            <FormattedMessage id="sharing.project.received" />{" "}
            {/* <ListCount count={rentCount} /> */}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <TableLayout
              currentSearchTerm={currentSearchTerm}
              items={requestSharedItems}
              fetchNextPage={fetchNextRequestSharedItemsPage}
              hasNextPage={hasNextRequestSharedItemsPage}
              isFetchingData={isFetchingRequestSharedItemsData}
            >
              <SharedTable
                items={requestSharedItems}
                type={SHARED_DATA_TYPE.PROJECT}
                fromOrTo="to"
              />
            </TableLayout>
          </TabPanel>
          <TabPanel>
            <TableLayout
              currentSearchTerm={currentSearchTerm}
              items={responseSharedItems}
              fetchNextPage={fetchNextResponseSharedItemsPage}
              hasNextPage={hasNextResponseSharedItemsPage}
              isFetchingData={isFetchingResponseSharedItemsData}
            >
              <SharedTable
                items={responseSharedItems}
                type={SHARED_DATA_TYPE.PROJECT}
                fromOrTo="from"
              />
            </TableLayout>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  )
}
