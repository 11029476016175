import { Button, Flex, FormControl, ModalBody, ModalFooter } from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"

import { TelegramAltIcon } from "@/components/Icons"
import { Modal } from "@/components/Modal"
import { P3 } from "@/components/Typographies"
import { useAuthenticatedUserContext } from "@/context/AuthenticatedUserContext"
import { SHARED_DATA_STATUS } from "@/domain/sharedData/constants"
import { getSharedEntityType } from "@/domain/sharedData/getSharedUtils"
import { isBuAdmin } from "@/domain/user/isBuAdmin"
import type { SharedDataJsonldReadCollectionSharedData } from "@/networking/api"
import { isNotNullNorUndefined } from "@/utils/ts-utils"
import { SharedDataStatusTag } from "../DomainTag/SharedDataStatusTag"
import { InterBUSection } from "./sections/InterBUSection"
import { PeopleSection } from "./sections/PeopleSection"
import { ProjectSection } from "./sections/ProjectSection"
import { ReceiverSection } from "./sections/ReceiverSection"
import { RequestSection } from "./sections/RequestSection"
import { RequestorSection } from "./sections/RequestorSection"

type Props = {
  isOpen: boolean
  onClose: () => void
  onOpenAccept: () => void
  onOpenDecline: () => void
  onOpenRevoke: () => void
  sharedEntity: SharedDataJsonldReadCollectionSharedData
}

export default function ShareResponseModal({
  isOpen,
  onClose,
  onOpenAccept,
  onOpenDecline,
  onOpenRevoke,
  sharedEntity,
}: Props) {
  const user = useAuthenticatedUserContext()
  // const isAuthenticatedAsBuAdmin = isBuAdmin()
  const isUserRequestor = sharedEntity.from?.id === user.user?.id

  const canAnswer = !isUserRequestor && isBuAdmin()

  const entityType = getSharedEntityType(sharedEntity)

  const onAccept = () => {
    onOpenAccept()
    onClose()
  }
  const onDecline = () => {
    onOpenDecline()
    onClose()
  }
  const onRevoke = () => {
    onOpenRevoke()
    onClose()
  }

  return (
    <Modal
      {...{ isOpen, onClose }}
      title={<FormattedMessage id={`userSharedData.${entityType}.modal.title2`} />}
      scrollBehavior="outside"
    >
      <ModalBody>
        <FormControl as={Flex} flexDir="column" gap="6">
          {isNotNullNorUndefined(sharedEntity.people) && (
            <PeopleSection people={sharedEntity.people} />
          )}
          {isNotNullNorUndefined(sharedEntity.project) && (
            <ProjectSection project={sharedEntity.project} />
          )}
          {isNotNullNorUndefined(sharedEntity.interBu) && (
            <InterBUSection interBu={sharedEntity.interBu} />
          )}
          {isUserRequestor ? (
            <ReceiverSection cluster={sharedEntity.toCluster} />
          ) : (
            <RequestorSection collaborator={sharedEntity.from} cluster={sharedEntity.fromCluster} />
          )}
          <RequestSection entity={sharedEntity} />
        </FormControl>
      </ModalBody>

      <ModalFooter>
        {canAnswer && sharedEntity.status?.code === SHARED_DATA_STATUS.ASKED && (
          <>
            <Button display="flex" gap="2" type="button" onClick={() => onDecline()}>
              <FormattedMessage id="userSharedData.declineRequest" />
              <TelegramAltIcon boxSize={4} />
            </Button>
            <Button display="flex" gap="2" type="button" onClick={() => onAccept()}>
              <FormattedMessage id="userSharedData.acceptRequest" />
              <TelegramAltIcon boxSize={4} />
            </Button>
          </>
        )}
        {canAnswer && sharedEntity.status?.code === SHARED_DATA_STATUS.ACCEPTED && (
          <Button display="flex" gap="2" type="button" onClick={() => onRevoke()}>
            <FormattedMessage id="userSharedData.revokeRequest" />
            <TelegramAltIcon boxSize={4} />
          </Button>
        )}
        {(!canAnswer ||
          (sharedEntity.status?.code !== SHARED_DATA_STATUS.ASKED &&
            sharedEntity.status?.code !== SHARED_DATA_STATUS.ACCEPTED)) && (
          <>
            <P3>
              <FormattedMessage id="sharing.sharedItem.status" /> :
            </P3>
            <SharedDataStatusTag status={sharedEntity.status?.code} />
          </>
        )}
      </ModalFooter>
    </Modal>
  )
}
