import type { ComponentProps } from "react"

import { P2B } from "../Typographies"

type Props = React.PropsWithChildren<{ disabled?: boolean } & ComponentProps<typeof P2B>>

export default function FieldLabel({ children, disabled, ...p2bProps }: Props) {
  return (
    <P2B color={disabled ? "grey.300" : "grey.600"} {...p2bProps}>
      {children}
    </P2B>
  )
}
