export const extractPageNumberFromNextPageRoute = (route?: string) => {
  if (route === undefined) return undefined

  const url = new URL(route, import.meta.env.VITE_API_BASE_URL)
  const nextPageString = url.searchParams.get("page")

  if (nextPageString === null) return undefined

  const nextPage = Number.parseInt(nextPageString)

  return nextPage
}
