import { FormattedMessage } from "react-intl"

import { CheckIcon, PauseIcon } from "@/components/Icons"
import { Tag } from "@/components/Tag"
import { SHARED_DATA_STATUS, type SHARED_DATA_STATUS_VALUE } from "@/domain/sharedData/constants"

type Props = {
  status?: SHARED_DATA_STATUS_VALUE | string
}

export const SharedDataStatusTag: React.FC<Props> = ({ status }) => {
  if (status === SHARED_DATA_STATUS.ACCEPTED) {
    return (
      <Tag icon={CheckIcon} bg="green.100" color="green.800" pr={2}>
        <FormattedMessage id="userSharedData.status.accepted" />
      </Tag>
    )
  }

  if (status === SHARED_DATA_STATUS.DENIED) {
    return (
      <Tag icon={CheckIcon} bg="red.100" color="red.800" pr={2}>
        <FormattedMessage id="userSharedData.status.denied" />
      </Tag>
    )
  }

  if (status === SHARED_DATA_STATUS.REVOKED) {
    return (
      <Tag bg="grey.200" px={2}>
        <FormattedMessage id="userSharedData.status.revoked" />
      </Tag>
    )
  }

  return (
    <Tag icon={PauseIcon} bg="grey.200" pr={2}>
      <FormattedMessage id="userSharedData.status.asked" />
    </Tag>
  )
}
