import { useAuthenticatedUserContext } from "@/context/AuthenticatedUserContext"
import { type CollaboratorJsonldUserReadItem, useGetUser } from "@/networking/api"

const findBuAdminRoleInCollaboratorsCluster = (collaborators: CollaboratorJsonldUserReadItem[]) => {
  for (const collab of collaborators) {
    const rel_clusters = collab.cluster
    for (const rel_cluster of rel_clusters ?? []) {
      if (rel_cluster.role?.code === "manager") return true
    }
  }
  return false
}

export const isBuAdmin = () => {
  const user = useAuthenticatedUserContext()
  const { data: userInfos } = useGetUser(user.user?.id ?? "0")
  return (
    (user.user?.isBuAdmin ?? false) &&
    findBuAdminRoleInCollaboratorsCluster(userInfos?.collaborators ?? [])
  )
}
