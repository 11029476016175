import { useRadioGroup } from "@chakra-ui/react"
import {
  type FieldPath,
  type FieldValues,
  type UseControllerProps,
  useController,
} from "react-hook-form"

/** React Hook to manage a group of radio inputs with RHF */
export const useControlledRadioProps = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: UseControllerProps<TFieldValues, TName>
) => {
  const { field } = useController(props)

  return useRadioGroup({ ...field })
}
