import { useIntl } from "react-intl"

import { useAppToast } from "./useAppToast"

export const useGenericErrorToast = () => {
  const toast = useAppToast()
  const intl = useIntl()

  return () => toast({ status: "error", title: intl.formatMessage({ id: "error.generic.title" }) })
}
