import { Tag as ChakraTag, TagLabel, type TagProps, type createIcon } from "@chakra-ui/react"
import { forwardRef } from "react"

type Props = {
  icon?: ReturnType<typeof createIcon>
} & TagProps

export const Tag = forwardRef<HTMLSpanElement, Props>(
  ({ children, icon: Icon, ...tagProps }, ref) => (
    <ChakraTag {...tagProps} ref={ref}>
      {Icon && <Icon w={3} h={3} mr={1} />}
      <TagLabel>{children}</TagLabel>
    </ChakraTag>
  )
)

Tag.displayName = "Tag"
