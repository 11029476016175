import SectionColumn from "@/components/Sections/SectionColumn"
import SectionContainer from "@/components/Sections/SectionContainer"
import SingleField from "@/components/Sections/SingleField"
import { H4 } from "@/components/Typographies"
import type { SharedDataJsonldReadCollectionSharedDataInterBu } from "@/networking/api"
import { FormattedMessage, useIntl } from "react-intl"

type Props = {
  interBu: SharedDataJsonldReadCollectionSharedDataInterBu
}

export const InterBUSection = ({ interBu }: Props) => {
  const intl = useIntl()

  return (
    <>
      <H4 color="primary.500">
        <FormattedMessage id="userSharedData.interbu.details" />
      </H4>
      <SectionContainer title="">
        <SectionColumn>
          <SingleField label={intl.formatMessage({ id: "userSharedData.interbu.name" })}>
            {/*getDisplayedName(project?.property ?? undefined)*/}
            {interBu?.id}
          </SingleField>
        </SectionColumn>
      </SectionContainer>
    </>
  )
}
