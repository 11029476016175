import { useIntl } from "react-intl"

export const useFormatDate = (
  { includeTime }: { includeTime?: boolean } = { includeTime: false }
) => {
  const { locale } = useIntl()

  return function formatDate(date: string | Date) {
    const dateObject = new Date(date)

    return includeTime ? dateObject.toLocaleString(locale) : dateObject.toLocaleDateString(locale)
  }
}
