import SingleField from "@/components/Sections/SingleField"
import { H3 } from "@/components/Typographies"
import { Flex } from "@chakra-ui/react"
import { FormattedMessage, useIntl } from "react-intl"

export const MailSign: React.FC = () => {
  const intl = useIntl()
  return (
    <>
      <Flex flexDir="column" gap="6" px="8" py="12">
        <H3>
          <FormattedMessage id="resources.mail_sign" />
        </H3>
      </Flex>

      <Flex flexDir="column" gap="6" px="8" py="12">
        <SingleField label={intl.formatMessage({ id: "resources.mail_sign.model_1" })}>
          <pre>
            {"---\n"}
            {"Nom prénom du collaborateur\n"}
            {"Fonction occupée\n"}
            {"Entité Barnes\n"}
            {"\n"}
            {"Tél. +33 1 2345678\n"}
          </pre>
        </SingleField>
      </Flex>
    </>
  )
}
