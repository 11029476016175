import {
  Button,
  Modal as ChakraModal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  chakra,
} from "@chakra-ui/react"
import { FormattedMessage } from "react-intl"

import { MultiplyIcon } from "@/components/Icons"
import { H4 } from "@/components/Typographies"

import styles from "./Modal.module.scss"

type Props = React.PropsWithChildren<{
  isOpen: boolean
  onClose: () => void
  title: React.ReactNode
  size?: string
  scrollBehavior?: "inside" | "outside"
}>

export const Modal: React.FC<Props> = ({
  isOpen,
  onClose,
  title,
  children,
  size = "2xl",
  scrollBehavior = "inside",
}) => {
  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      scrollBehavior={scrollBehavior}
      size={size}
    >
      <ModalOverlay />
      <ModalContent borderRadius={0} className={styles.modalContent}>
        <ModalHeader display="flex" justifyContent="space-between" alignItems="flex-end" p="10">
          <H4>{title}</H4>
          <Button variant="link" display="flex" gap="2" onClick={onClose} color="primary.500">
            <chakra.span textStyle="linkM">
              <FormattedMessage id="close" />
            </chakra.span>
            <MultiplyIcon boxSize={6} />
          </Button>
        </ModalHeader>
        {children}
      </ModalContent>
    </ChakraModal>
  )
}
