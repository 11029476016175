import { Flex, Grid } from "@chakra-ui/react"

import ConfidentialLines from "../ConfidentialLines"
import FieldLabel from "./FieldLabel"
import type { SharedFieldProps } from "./types"

type Props = React.PropsWithChildren<{ label: string } & SharedFieldProps>

export default function MultilineField({ label, children, isConfidential }: Props) {
  return (
    <Grid gap="6" gridTemplateColumns="160px 1fr">
      <FieldLabel py={2} disabled={isConfidential}>
        {label}
      </FieldLabel>
      <Flex py={2} flexDirection="column" alignSelf="start" flexShrink={0} flexGrow={1}>
        {isConfidential ? (
          <>
            <ConfidentialLines py={2} />
            <ConfidentialLines py={2} />
          </>
        ) : (
          <>{children}</>
        )}
      </Flex>
    </Grid>
  )
}
