import { Flex, type FlexboxProps, Grid } from "@chakra-ui/react"

import ConfidentialLines from "../ConfidentialLines"
import FieldLabel from "./FieldLabel"
import type { SharedFieldProps } from "./types"

type Props = React.PropsWithChildren<{ label: string } & SharedFieldProps & FlexboxProps>

export default function SingleField({
  label,
  children,
  isConfidential,
  alignItems,
  flexDir,
}: Props) {
  return (
    <Grid gap="6" gridTemplateColumns="160px 1fr" alignItems={alignItems ?? "start"}>
      <FieldLabel py={2} disabled={isConfidential}>
        {label}
      </FieldLabel>
      {isConfidential ? (
        <ConfidentialLines py={2} />
      ) : (
        <Flex py={2} alignSelf="center" flexShrink={0} flexGrow={1} flexDir={flexDir ?? "row"}>
          {children}
        </Flex>
      )}
    </Grid>
  )
}
