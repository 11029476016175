import { LinkButtonWithIcon } from "@/components/Buttons/LinkButtonWithIcon"
import { EyeIcon } from "@/components/Icons"
import SectionColumn from "@/components/Sections/SectionColumn"
import SectionContainer from "@/components/Sections/SectionContainer"
import SingleField from "@/components/Sections/SingleField"
import { H4 } from "@/components/Typographies"
import { getProjectOfferLinkSheet } from "@/domain/propertyOffer/getProjectOfferLinkSheet"
import type { SharedDataJsonldReadCollectionSharedDataProject } from "@/networking/api"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

type Props = {
  project: SharedDataJsonldReadCollectionSharedDataProject
}

export const ProjectSection = ({ project }: Props) => {
  const intl = useIntl()
  const navigateTo = useNavigate()

  return (
    <>
      <H4 color="primary.500">
        <FormattedMessage id="userSharedData.project.details" />
      </H4>
      <SectionContainer title="">
        <SectionColumn>
          <SingleField label={intl.formatMessage({ id: "userSharedData.project.name" })}>
            {/*getDisplayedName(project?.property ?? undefined)*/}
            {project?.id}
            <LinkButtonWithIcon
              display="inline-block"
              icon={EyeIcon}
              onClick={() => navigateTo(getProjectOfferLinkSheet(project))}
            />
          </SingleField>
        </SectionColumn>
      </SectionContainer>
    </>
  )
}
