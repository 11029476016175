import { Box, Flex } from "@chakra-ui/react"

import { H4 } from "@/components/Typographies"
import { isNotBlank, isNotNullNorUndefined } from "@/utils/ts-utils"

type Props = React.PropsWithChildren<{
  title: string
  footer?: React.ReactNode
  isConfidential?: boolean
  direction?: "column" | "row"
}>

export default function SectionContainer({
  title,
  footer,
  children,
  isConfidential,
  direction = "row",
}: Props) {
  return (
    <Box>
      <Flex flexDir={direction} gap={direction === "row" ? "8" : "4"}>
        {isNotBlank(title) && (
          <Flex flexDir="column" gap="4" flexBasis={direction === "row" ? "180px" : "0px"}>
            <H4 color={isConfidential ? "grey.300" : "primary.500"}>{title}</H4>
          </Flex>
        )}

        {children}
      </Flex>
      {isNotNullNorUndefined(footer) && <Box pt="4">{footer}</Box>}
    </Box>
  )
}
