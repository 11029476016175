import type { RelatedCluster } from "@/domain/cluster/constants"
import type { RelatedCollaborator } from "@/domain/collaborator/constants"

export type EntityLocked = {
  id: string
  collaborator?: (RelatedCollaborator & { id: string })[]
  cluster?: (RelatedCluster & { id: string })[]
} & ({ name?: string } | { firstname?: string; lastname?: string })

export const SHARING_REQUEST_MOTIVATION = {
  NEW_PROJECT: "new_project",
  JOIN_PROJECT: "access_data",
} as const
export type SharingRequestMotivation = typeof SHARING_REQUEST_MOTIVATION
export type SharingRequestMotivationType = SharingRequestMotivation[keyof SharingRequestMotivation]

export const SHARING_RESPONSE_MOTIVATION = {
  ALREADY_KNOWS_CUSTOMER: "knows_customer",
  PROJECT_NOT_POSSIBLE: "not_possible",
  NOT_COMPETENT: "not_competent",
  OTHER: "other",
} as const
export type SharingResponseMotivation = typeof SHARING_RESPONSE_MOTIVATION
export type SharingResponseMotivationType =
  SharingResponseMotivation[keyof SharingResponseMotivation]
