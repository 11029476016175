import type { InfiniteData } from "@tanstack/react-query"

export type GetCollectionData<T> = {
  "hydra:member": T[]
  "hydra:totalItems"?: number
  "hydra:view"?: {
    "@id"?: string
    "@type"?: string
    "hydra:first"?: string
    "hydra:last"?: string
    "hydra:previous"?: string
    "hydra:next"?: string
  }
  "hydra:search"?: unknown
}

export const getInfiniteQueryData = <T>(data?: InfiniteData<GetCollectionData<T>>) =>
  data?.pages.flatMap(page => page["hydra:member"])

export const getInfiniteQueryItemsCount = <T>(data?: InfiniteData<GetCollectionData<T>>) =>
  data?.pages[0]?.["hydra:totalItems"]
