import { Link } from "@chakra-ui/react"

import { isNotNullNorUndefined } from "@/utils/ts-utils"
import { PhoneIcon } from "../Icons"
import { P3 } from "../Typographies"

type Props = {
  phone?: string | null
  dft?: string
  smaller?: boolean
  withIcon?: boolean
}

export default function FormattedPhone({ phone, dft, smaller, withIcon }: Props) {
  const Element = () =>
    isNotNullNorUndefined(phone) ? (
      <Link href={`mailto:${phone}`} target="_blank">
        {smaller ? <P3>{phone}</P3> : phone}
      </Link>
    ) : (
      <>{dft ?? ""}</>
    )

  return withIcon ? (
    <>
      <PhoneIcon boxSize="4" color="grey.300" />
      <Element />
    </>
  ) : (
    <Element />
  )
}
