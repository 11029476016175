import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Textarea,
} from "@chakra-ui/react"
import { useQueryClient } from "@tanstack/react-query"
import { useForm } from "react-hook-form"
import { FormattedMessage } from "react-intl"

import { TelegramAltIcon } from "@/components/Icons"
import { Modal } from "@/components/Modal"
import { SHARED_DATA_STATUS } from "@/domain/sharedData/constants"
import { getSharedEntityName, getSharedEntityType } from "@/domain/sharedData/getSharedUtils"
import { useGenericErrorToast } from "@/hooks/useGenericErrorToast"
import {
  type GetUserSharedDatasParams,
  type SharedDataJsonldReadCollectionSharedData,
  type SharedDataPostSharedDataInputJsonldWriteItemSharedData,
  getGetUserSharedDatasQueryKey,
  useUpdateUserSharedData,
} from "@/networking/api"
import { SHARING_RESPONSE_MOTIVATION } from "../Sections/ContactDetailsLockedSection/types"

type Props = {
  isOpen: boolean
  onClose: () => void
  sharedEntity: SharedDataJsonldReadCollectionSharedData
}

export default function ShareAcceptModal({ isOpen, onClose, sharedEntity }: Props) {
  const entityType = getSharedEntityType(sharedEntity)
  const userQueryKey = { id: sharedEntity.id } as GetUserSharedDatasParams

  const defaultValues = {
    id: sharedEntity.id,
    message: "",
    subject: SHARING_RESPONSE_MOTIVATION.ALREADY_KNOWS_CUSTOMER as string,
  }

  const { control, handleSubmit, register } = useForm<
    Partial<SharedDataPostSharedDataInputJsonldWriteItemSharedData>
  >({
    defaultValues,
  })

  const { mutate } = useUpdateUserSharedData()
  const queryClient = useQueryClient()
  const genericErrorToast = useGenericErrorToast()

  const postShareRequest = (
    formData: Partial<SharedDataPostSharedDataInputJsonldWriteItemSharedData>
  ) => {
    mutate(
      {
        data: {
          id: sharedEntity.id,
          subjectAnswer: "",
          messageAnswer: formData.message,
          status: SHARED_DATA_STATUS.ACCEPTED,
        },
      },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries(getGetUserSharedDatasQueryKey(userQueryKey))
          onClose()
        },
        onError: genericErrorToast,
      }
    )
  }

  return (
    <Modal
      {...{ isOpen, onClose }}
      title={<FormattedMessage id={`userSharedData.${entityType}.modal.title_accept`} />}
      scrollBehavior="outside"
    >
      <form onSubmit={handleSubmit(postShareRequest)}>
        <ModalBody>
          <FormControl as={Flex} flexDir="column" gap="6">
            <Box>
              <FormLabel>
                <FormattedMessage id="userSharedData.modal.bu" />
              </FormLabel>
              <Input value={getSharedEntityName(sharedEntity)} disabled />
            </Box>

            <Box>
              <FormLabel>
                <FormattedMessage id="userSharedData.comment" />
              </FormLabel>
              <Textarea {...register("message")} />
            </Box>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button display="flex" gap="2" type="submit">
            <FormattedMessage id="userSharedData.modal.sendRequest" />
            <TelegramAltIcon boxSize={4} />
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
