import { Link } from "react-router-dom"

import { SharedDataStatusTag } from "@/components/DomainTag/SharedDataStatusTag"
import FormattedDate from "@/components/FormattedDate"
import { RefLabel } from "@/components/RefLabel"
import TextCell from "@/components/TableCells/TextCell"
import { getSharedEntityName } from "@/domain/sharedData/getSharedUtils"
import type { SharedDataJsonldReadCollectionSharedData } from "@/networking/api"
import { EMPTY_PLACEHOLDER } from "@/utils/constants"
import { LinkBox, LinkOverlay, Tr } from "@chakra-ui/react"

type Props = {
  item: SharedDataJsonldReadCollectionSharedData
  fromOrTo: "from" | "to"
  onOpen: (id: string) => void
}

export const SharedTableRow = ({ item, fromOrTo, onOpen }: Props) => {
  const name = getSharedEntityName(item)
  const cluster = fromOrTo === "from" ? item.fromCluster : item.toCluster

  return (
    <LinkBox as={Tr}>
      <TextCell>
        <LinkOverlay as={Link} onClick={() => onOpen(item.id)} className="link-overlay-right">
          <RefLabel label={item.type} />
        </LinkOverlay>
      </TextCell>
      <TextCell>{name}</TextCell>
      <TextCell>{cluster?.name}</TextCell>
      <TextCell>
        <FormattedDate date={item.createdAt} dft={EMPTY_PLACEHOLDER} />
      </TextCell>
      <TextCell>
        <FormattedDate
          date={item.revokedAt ?? item.deniedAt ?? item.acceptedAt}
          dft={EMPTY_PLACEHOLDER}
        />
      </TextCell>
      <TextCell>
        <SharedDataStatusTag status={item.status?.code} />
      </TextCell>
    </LinkBox>
  )
}
