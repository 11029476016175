export const formatFileSize = (size: number): string => {
  if (size > 1000000000) return `${Math.round(size / 10000000) / 100} Go`
  if (size > 1000000) return `${Math.round(size / 10000) / 100} Mo`
  if (size > 1000) return `${Math.round(size / 10) / 100} Ko`
  return `${size} octets`
}

export const getFilename = (filepath?: string | null): string => {
  return (filepath ?? "")
    .replace(/(^[\\\/]+|[\\\/]+$)/, "")
    .replace(/^.*[\\\/]([^\\\/]+)$/, "$1")
    .replace(/[#\?].*$/, "")
}

export const getDir = (filepath?: string | null): string => {
  return (filepath ?? "")
    .replace(/(^[\\\/]+|[\\\/]+$)/, "")
    .replace(/^(.*[\\\/])[^\\\/]+$/, "$1")
    .replace(/[#\?].*$/, "")
}
