import { Input, InputGroup, InputLeftElement, type InputProps } from "@chakra-ui/react"
import { forwardRef } from "react"

import { SearchIcon } from "@/components/Icons"

type Props = InputProps

export const SearchbarInput = forwardRef<HTMLInputElement, Props>((inputProps, ref) => (
  <InputGroup>
    <InputLeftElement pointerEvents="none" p="4">
      <SearchIcon color="red.500" boxSize="6" />
    </InputLeftElement>
    <Input pl="12" size="md" variant="searchbar" {...inputProps} ref={ref} />
  </InputGroup>
))

SearchbarInput.displayName = "SearchbarInput"
